import { CanvasMap, MapMarker } from '@shared/lib/react-canvas';
import { Dispatch, FC, SetStateAction } from 'react';
import { VisitorMarkerType, VisitorRoleEnum } from '@pages/Visitors/types';
import { useGetVisitorById, useGetVisitorsByIds, useVisitorsPosition } from '@pages/Visitors/hooks';

import { LayoutContent } from '@shared/widgets';
import { MapFilters } from '../MapFilters';
import { SecurityUserAreaResponseDto } from '@shared/services/apiService/apiService_base';
import VisitorCard from '@entities/VisitorCard';
import styles from './mapSection.module.scss';
import { useToggle } from '@shared/hooks';

type MapSectionProps = {
  activeRole: VisitorRoleEnum | string;
  setActiveRole: Dispatch<SetStateAction<VisitorRoleEnum | string>>;
  areaInfo?: SecurityUserAreaResponseDto;
  isGetAreaInfoLoading: boolean;
};

export const MapSection: FC<MapSectionProps> = ({ areaInfo, activeRole, setActiveRole }) => {
  const { state: isVisitorCardVisible, setTrue: openVisitorCard, setFalse: closeVisitorCard } = useToggle();

  const { activeMarker, visitorInfo, setActiveMarker } = useGetVisitorById();

  const { visitorsPositions, visitorsIds } = useVisitorsPosition();

  // Backend-API требует валидный UUID для пользователя, но если прилетают координаты неизвестных visitors (не можем определить uuid), то прилетает целочисленный person id и в таком случае в ответ на запрос прилетает 400 код и даже известные визиторы отрадаются красными. Поэтому фильтруем только валидные visitor UUIDs
  const validVisitorIds = visitorsIds.filter(id => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

  const { visitors: visitorsList } = useGetVisitorsByIds({ ids: validVisitorIds });

  const handleCloseVisitorCard = () => {
    closeVisitorCard();
    setActiveMarker(null);
  };

  const handleMarkerClick = (marker: VisitorMarkerType) => {
    openVisitorCard();
    setActiveMarker(marker);
  };

  const visitors: VisitorMarkerType[] = visitorsPositions.map(visitor => {
    const visitorPositionFindById = visitorsList && visitorsList.find(item => item.id === visitor.visitorUuid);
    const isVisitorUnidentified = !visitorPositionFindById?.hasBeenFound || !visitorPositionFindById?.hasAccess;

    return {
      ...visitor,
      role: isVisitorUnidentified ? VisitorRoleEnum.UNIDENTIFIED : (visitorPositionFindById.role as VisitorRoleEnum),
    };
  });

  return (
    <LayoutContent>
      <div className={styles.root}>
        {isVisitorCardVisible && <VisitorCard visitorInfo={visitorInfo} closeCallback={handleCloseVisitorCard} />}
        <MapFilters activeRole={activeRole} setActiveRole={setActiveRole} />
        {areaInfo?.planUrl && (
          <CanvasMap image={areaInfo?.planUrl}>
            {visitors &&
              visitors.map(marker => (
                <MapMarker
                  key={marker.visitorUuid}
                  markerKey={marker.visitorUuid}
                  coords={{ x: marker.x, y: marker.y }}
                  role={marker.role}
                  isActive={marker.visitorUuid === activeMarker?.visitorUuid}
                  isFocused={activeRole ? activeRole === marker.role : true}
                  isAvatar={false}
                  onClick={() => handleMarkerClick(marker)}
                />
              ))}
          </CanvasMap>
        )}
      </div>
    </LayoutContent>
  );
};
