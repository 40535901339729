import { baseApiLog as api } from '../../store/api/baseApi_log';
export const addTagTypes = ['Prometheus', 'Security Users', '[v1] Visitors Biometric Logs'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      appControllerGetHello: build.query<AppControllerGetHelloApiResponse, AppControllerGetHelloApiArg>({
        query: () => ({ url: `/` }),
      }),
      appControllerGetUserFromPayload: build.query<
        AppControllerGetUserFromPayloadApiResponse,
        AppControllerGetUserFromPayloadApiArg
      >({
        query: () => ({ url: `/iam` }),
      }),
      prometheusControllerWebhook: build.query<
        PrometheusControllerWebhookApiResponse,
        PrometheusControllerWebhookApiArg
      >({
        query: () => ({ url: `/metrics` }),
        providesTags: ['Prometheus'],
      }),
      securityUserControllerGetAttendanceLogs: build.query<
        SecurityUserControllerGetAttendanceLogsApiResponse,
        SecurityUserControllerGetAttendanceLogsApiArg
      >({
        query: queryArg => ({
          url: `/v1/security-users/offices/${queryArg.officeId}/attendance-logs`,
          params: { visitorId: queryArg.visitorId, limit: queryArg.limit, page: queryArg.page },
        }),
        providesTags: ['Security Users'],
      }),
      securityUserControllerGetNotifications: build.query<
        SecurityUserControllerGetNotificationsApiResponse,
        SecurityUserControllerGetNotificationsApiArg
      >({
        query: queryArg => ({
          url: `/v1/security-users/offices/${queryArg.officeId}/notifications`,
          params: { suspiciousActivityTab: queryArg.suspiciousActivityTab, limit: queryArg.limit, page: queryArg.page },
        }),
        providesTags: ['Security Users'],
      }),
      securityUserControllerVerifyNotification: build.mutation<
        SecurityUserControllerVerifyNotificationApiResponse,
        SecurityUserControllerVerifyNotificationApiArg
      >({
        query: queryArg => ({
          url: `/v1/security-users/attendances/${queryArg.attendanceLogId}/verify-and-comment`,
          method: 'PATCH',
          body: queryArg.notificationUpdateRequestDto,
        }),
        invalidatesTags: ['Security Users'],
      }),
      biometricLogControllerCreateBiometricLogs: build.mutation<
        BiometricLogControllerCreateBiometricLogsApiResponse,
        BiometricLogControllerCreateBiometricLogsApiArg
      >({
        query: () => ({ url: `/v1/visitors/biometric-logs`, method: 'POST' }),
        invalidatesTags: ['[v1] Visitors Biometric Logs'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as apiService_log };
export type AppControllerGetHelloApiResponse = /** status 200  */ string;
export type AppControllerGetHelloApiArg = void;
export type AppControllerGetUserFromPayloadApiResponse = /** status 200  */ object;
export type AppControllerGetUserFromPayloadApiArg = void;
export type PrometheusControllerWebhookApiResponse = unknown;
export type PrometheusControllerWebhookApiArg = void;
export type SecurityUserControllerGetAttendanceLogsApiResponse = /** status 200  */ AttendanceLogsPaginateResponseDto;
export type SecurityUserControllerGetAttendanceLogsApiArg = {
  officeId: string;
  visitorId?: string;
  limit?: number;
  page?: number;
};
export type SecurityUserControllerGetNotificationsApiResponse = /** status 200  */ NotificationLogsPaginateResponseDto;
export type SecurityUserControllerGetNotificationsApiArg = {
  suspiciousActivityTab: 'not_verified' | 'verified';
  limit?: number;
  page?: number;
  officeId: string;
};
export type SecurityUserControllerVerifyNotificationApiResponse = /** status 200  */ NotificationLogResponseDto;
export type SecurityUserControllerVerifyNotificationApiArg = {
  attendanceLogId: string;
  notificationUpdateRequestDto: NotificationUpdateRequestDto;
};
export type BiometricLogControllerCreateBiometricLogsApiResponse = /** status 200  */ BaseStatusResponseDto;
export type BiometricLogControllerCreateBiometricLogsApiArg = void;
export type SuspiciousActivityKind =
  | 'bluetooth_undefined'
  | 'biometrics_undefined'
  | 'bluetooth_and_biometrics_undefined';
export type AttendanceLogResponseDto = {
  id: string;
  visitorId: string;
  officeId: string;
  accessKey: string;
  accessPointId: string;
  suspiciousActivity: SuspiciousActivityKind | null;
  createdAt: string;
};
export type ResponseMetaDto = {
  count: number;
  total: number;
  page: number;
  pageCount: number;
  perPage: number;
};
export type AttendanceLogsPaginateResponseDto = {
  data: AttendanceLogResponseDto[];
  meta: ResponseMetaDto;
};
export type VerificationLogStatusKind =
  | 'verify_status_unspecified'
  | 'verify_status_verified'
  | 'verify_status_violation';
export type NotificationLogResponseDto = {
  id: string;
  attendance_log_id: string;
  visitor_id: string;
  access_point_id: string;
  verify_status: VerificationLogStatusKind | null;
  security_verifier_id: string | null;
  security_comment: string | null;
  service_verifier_name: string | null;
  service_comment: string | null;
  suspicion_bluetooth_undefined: number;
  suspicion_biometric_undefined: number;
  violation_access_denied: number;
  violation_reader_not_found: number;
  violation_incorrect_geo_location: number;
  violation_using_static_access_key: number;
  violation_internal_error: number;
  violation_controller_not_found: number;
  createdAt: string;
};
export type NotificationLogsPaginateResponseDto = {
  data: NotificationLogResponseDto[];
  meta: ResponseMetaDto;
};
export type NotificationUpdateRequestDto = {
  isViolated?: boolean | null;
  comment?: string | null;
};
export type BaseStatusResponseDto = {
  status: number;
  ok: boolean;
};
export const {
  useAppControllerGetHelloQuery,
  useLazyAppControllerGetHelloQuery,
  useAppControllerGetUserFromPayloadQuery,
  useLazyAppControllerGetUserFromPayloadQuery,
  usePrometheusControllerWebhookQuery,
  useLazyPrometheusControllerWebhookQuery,
  useSecurityUserControllerGetAttendanceLogsQuery,
  useLazySecurityUserControllerGetAttendanceLogsQuery,
  useSecurityUserControllerGetNotificationsQuery,
  useLazySecurityUserControllerGetNotificationsQuery,
  useSecurityUserControllerVerifyNotificationMutation,
  useBiometricLogControllerCreateBiometricLogsMutation,
} = injectedRtkApi;
